import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { fade } from 'src/app/core/animations/fade.animation';
import { IToast } from 'src/app/core/interfaces/toast-helper.interface';
import { IconsComponent } from '../../icons/icons.component';
import { ToastService } from '../toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    animations: [fade],
    imports: [NgClass, IconsComponent, TranslateModule]
})
export class ToastComponent {
	private _toast: IToast = undefined;
	iconColors = {
		warning: 'text-s-2-500',
		success: 'text-s-1-500',
		info: 'text-gb-3-500',
		error: 'text-s-3-500',
	};

	borderColors = {
		warning: 'border-s-2-500',
		success: 'border-s-1-500',
		info: 'border-gb-3-500',
		error: 'border-s-3-500',
	};

	icon = {
		warning: 'faWarning',
		success: 'faSuccess',
		info: 'faInfo',
		error: 'faError',
	};

	DEFAULT_DURATION = 3_000;

	constructor(private _toastService: ToastService) {
		this._toastService.getToast().subscribe((toast: IToast) => {
			this._toast = toast;

			setTimeout(
				() => this.dismiss(),
				toast.durationMilli ?? this.DEFAULT_DURATION,
			);
		});
	}

	public get toast(): IToast {
		return this._toast;
	}

	public dismiss(): void {
		this._toast = undefined;
	}
}
